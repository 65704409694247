import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactGA from 'react-ga4';
import ShareButtons from './social/ShareButtons';
import SEO from './seo/SEO';
import ContentAdComponent from './ContentAdComponent';
import * as Dialog from '@radix-ui/react-dialog';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import '../css/DetailPage.css';
import '../css/radixdialog.css';
import "../css/imagecarddialog.css";

const ExampleDetail = () => {
  const { id } = useParams();
  const [exampleData, setExampleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [dialogSize, setDialogSize] = useState({ width: 'auto', height: 'auto' });
  const apiUrl = process.env.REACT_APP_CMS_API_BASE_URL;

  // Utility function for truncating text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  useEffect(() => {
    const fetchExampleDetail = async () => {
      try {
        const query = `
          query($id: ID!) {
            t2Iexample(id: $id) {
              prompt
              negativeprompt
              img {
                url
              }
            }
          }
        `;
        const variables = { id };

        const response = await axios.post(`${apiUrl}/graphql`, { query, variables });
        const example = response.data.data.t2Iexample;
        const exampleWithUrl = {
          ...example,
          url: `${apiUrl}${example.img[0].url}`
        };

        setExampleData(exampleWithUrl);
        setError(null);
      } catch (err) {
        console.error('Error fetching example detail:', err);
        setError('Error fetching example detail.');
        setExampleData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchExampleDetail();
    ReactGA.send({ hitType: 'pageview', page: `/image-example/${id}`, title: 'Example Detail Page' });
  }, [id, apiUrl]);

  useEffect(() => {
    if (showImageDialog && exampleData && exampleData.url) {
      const img = new Image();
      img.src = exampleData.url;
      img.onload = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const imgWidth = img.width;
        const imgHeight = img.height;
  
        let width, height;
  
        if (imgWidth > screenWidth || imgHeight > screenHeight) {
          const widthRatio = screenWidth / imgWidth;
          const heightRatio = screenHeight / imgHeight;
          const ratio = Math.min(widthRatio, heightRatio) * 0.8; // 80% of the screen
          width = imgWidth * ratio;
          height = imgHeight * ratio;
        } else {
          width = imgWidth;
          height = imgHeight;
        }
  
        setDialogSize({ width, height });
      };
      img.onerror = () => {
        console.error('Failed to load image:', exampleData.url);
      };
    }
  }, [showImageDialog, exampleData]);  

  if (loading) {
    return <div className="loading-screen">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!exampleData) {
    return <div className="no-data-message">No data available.</div>;
  }

  const { prompt, negativePrompt, url } = exampleData;
  const truncatedPrompt = truncateText(prompt, 200);
  const currentURL = `https://llmstock.com/image-example/${id}`;

  return (
    <div>
      <div className="example-detail-container p-4">
      <SEO title={`Example Detail - ${prompt}`} description="Details of the example image and prompt." />

        <div className="header text-center my-6">
          <h1 className="text-4xl font-bold text-gray-700">Example Image Detail</h1>
        </div>

        <div className="image-container mb-8 flex justify-center">
          <img
            src={url}
            alt="Example"
            className="example-image rounded-lg shadow-xl transform transition duration-300 hover:scale-105"
            onClick={() => setShowImageDialog(true)}
          />
        </div>

        <div className="prompt-container bg-white rounded-lg shadow-lg p-6 mb-8" style={{ background: 'linear-gradient(to right, #dce35b, #45b649)' }}>
          <div className="prompt-section mb-4">
            <h3 className="text-xl font-semibold text-gray-800">Prompt</h3>
            <div className="prompt-text flex justify-between items-center bg-gray-100 p-3 rounded-lg mt-2">
              <p className="text-gray-900">{prompt}</p>
              <button className="copy-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300" onClick={() => navigator.clipboard.writeText(prompt)}>Copy</button>
            </div>
          </div>

          <div className="negative-prompt-section">
            <h3 className="text-xl font-semibold text-gray-800">Negative Prompt</h3>
            <p className="bg-gray-100 p-3 rounded-lg mt-2">{negativePrompt}</p>
          </div>
        </div>

        <div className="share-buttons-section my-8">
          <ShareButtons
            url={currentURL}
            title={`Explore Text2Image Example - ${truncatedPrompt} on LLMStock`}
            description={`Check out this example on LLMStock's Text2Image: ${prompt}`}
            image={url}
          />
        </div>

        <ContentAdComponent adSlot='1527445564' />
      </div>
      <Dialog.Root open={showImageDialog} onOpenChange={setShowImageDialog}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlayImg bg-opacity-75 bg-gray-700" />
          <Dialog.Content className="DialogImageContent" style={{ width: dialogSize.width, height: dialogSize.height }}>
            <TransformWrapper>
              <TransformComponent>
                <img src={url} alt="Fullsize Example" className="w-full h-auto rounded-lg shadow-xl" />
              </TransformComponent>
            </TransformWrapper>
            <Dialog.Close asChild>
              <button className="DialogImageCloseButton text-white text-xl" aria-label="Close">
                &#x2715;
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};

export default ExampleDetail;
